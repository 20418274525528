<template>
  <q-padder top="sm" bottom="xs" horizontal="xs">
    <q-text tag="h1" variant="ui-small" ink="label" bold>{{ content.QoalaOnlineHomepage.exploreProductLabel }}</q-text>
    <q-row v-for="(product, index) in products" :key="index" class="softsell-section" :title="product.title">
      <q-general-info-card :image="product.image" @click="goToProduct(product.name)" :customContent="true">
        <q-text tag="div" class="content">
          <q-spacer bottom="xs">
            <q-text tag="h3" variant="ui-small" ink="label" bold>{{
              product.title
            }}</q-text>
          </q-spacer>
          <q-text tag="h4" variant="ui-tiny" ink="muted" class="clamp">{{
            product.desc
          }}</q-text>
          <q-text
            v-if="product.name !== 'Health'"
            tag="div"
            variant="ui-tiny"
            ink="muted"
            >{{ product.desc2 }}
            <q-text tag="span" ink="primary" variant="ui-baseline" bold>{{
              product.desc3
            }}</q-text>
          </q-text>
        </q-text>
      </q-general-info-card>
    </q-row>
  </q-padder>
</template>

<script>
/**
 * Soft Selling Section - Homepage
 * @author <doni.samalo@qoala.id> updated on: 19 June 2020
 */
import {
  Padder as QPadder,
  Text as QText,
  Spacer as QSpacer,
  Row as QRow,
} from '@qoala/ui';
import { GeneralInfoCard as QGeneralInfoCard } from '@qoala/ui/v2';
const HealthImage =
  'https://img.qoala.app/qapp-assets/images/homepage/health-protection.jpg';
const LifeImage =
  'https://img.qoala.app/qapp-assets/images/homepage/life-protection.jpg';
const CarImage =
  'https://img.qoala.app/qapp-assets/images/homepage/car-protection.png';

export default {
  components: {
    QPadder,
    QText,
    QSpacer,
    QRow,
    QGeneralInfoCard,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      products: [
        {
          name: 'Health',
          image: HealthImage,
          title: this.content.QoalaOnlineHomepage.bannerTitleHealth,
          desc: this.content.QoalaOnlineHomepage.bannerDescHealth,
        },
        {
          name: 'Life',
          image: LifeImage,
          title: this.content.QoalaOnlineHomepage.bannerTitleLife,
          desc: this.content.QoalaOnlineHomepage.bannerDescLife,
          desc2: this.content.QoalaOnlineHomepage.startFromLabel,
          desc3: 'Rp 18.000',
        },
        {
          name: 'Car',
          image: CarImage,
          title: this.content.QoalaOnlineHomepage.vehicleBannerTitle,
          desc: this.content.QoalaOnlineHomepage.vehicleBannerDesc,
        },
      ],
    };
  },
  methods: {
    imageProxy(src) {
      return `${src}?tr=w-120,h-102`;
    },
    goToProduct(product) {
      this.$analytics.pushEvent({
        event: 'Homepage',
        eventCategory: 'Homepage',
        eventAction: 'Soft-Selling',
        eventLabel: `Homepage-Click-SoftSell-${product}`,
      });
      const url = product.toLowerCase();
      this.$router.push(this.localePath(`edu-${url}`));
    },
  },
};
</script>
<style lang="scss">
.softsell-section {
  background: #ffffff;
  box-shadow: 0 1.5px 3px 0 #d8e7fd;
  border-radius: 8px;
  cursor: pointer;
  &:not(:last-of-type) {
    margin-bottom: 4px;
  }
  .eu-card-section {
    border-radius: 8px;
    .eu-imagecontainer {
      height: 102px;
      border-radius: 8px 0 0 8px;
    }
    .eu-custom-content {
      display: flex;
    }
  }
  .content {
    align-self: center;
    padding: 12px 8px;
    @media screen and (max-width: 480px) {
      padding: 7px 8px;
    }
    h3,
    h4 {
      margin-bottom: 0;
    }
    .clamp {
      display: block;
      display: -webkit-box;
      max-width: 100%;
      line-height: 1.5;
      font-weight: normal;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
